import { useLayoutEffect, useState } from "react";

// hooks
export const useScroll = (ids, offset) => {
    const [activeId, setActiveId] = useState("");

    const clamp = (value) => Math.max(0, value);

    const isBetween = (value, floor, ceil) => value >= floor && value <= ceil;

    useLayoutEffect(() => {
        const listener = () => {
            const scroll = window.pageYOffset;

            const position = ids
                .map((e) => {
                    const id = e.url;
                    const element = document.getElementById(id);

                    if (!element) return { id, top: -1, bottom: -1 };

                    const rect = element.getBoundingClientRect();
                    const top = clamp(rect.top + scroll - offset);
                    const bottom = clamp(rect.bottom + scroll - offset);

                    return { id, top, bottom };
                })
                .find(({ top, bottom }) => isBetween(scroll, top, bottom));
            setActiveId(position?.id || "");
        };

        listener();

        window.addEventListener("resize", listener);
        window.addEventListener("scroll", listener);

        return () => {
            window.removeEventListener("resize", listener);
            window.removeEventListener("scroll", listener);
        };
    }, [ids, offset]);

    return activeId;
};
